html, body, #app {
  height: 100%;
}

body {
  margin: 0px;
}

video {
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;
  background-color: black;
  padding-bottom: 100%;
}
